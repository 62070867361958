"use client";
import Header from "@/components/Header";
import { useEffect, useState } from "react";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";

const StickyHeader = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlHeaderVisibility = () => {
    if (typeof window !== "undefined") {
      // Check the scroll position
      const currentScrollY = window.scrollY;

      if (currentScrollY > 200 && currentScrollY > lastScrollY) {
        setIsVisible(false); // Hide header
      } else {
        setIsVisible(true); // Show header
      }

      setLastScrollY(currentScrollY); // Update the last scroll position
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlHeaderVisibility, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", controlHeaderVisibility);
    };
  }, [lastScrollY]);

  return (
    <div
      className={`fixed1 top-01 w-full bg-white shadow-md transition-transform duration-300 ease-in-out z-[999] ${
        !isVisible && "-translate-y-full"
      }`}
    >
      <Header />
    </div>
  );
};

export default StickyHeader;
